.Inventory {
  position: relative;
  background-color: white;
}

.Inventory-close {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}

.Inventory-programon {
  width: 60vw;
  padding: 10px;
}

.Inventory-programon-monsters {
  width: 100%;
}

.Inventory-programon-monsters-item {
  width: calc((60vw - 5 * 20px) / 5);
  margin: 0px 10px;
  text-align: center;
  border-bottom: 1px solid white;
  padding-bottom: 5px;
}

.Inventory-programon-monsters-item_selected {
  border-bottom: 1px solid black;
}

.Inventory-programon-monsters-item-avatar {
  width: 100px;
  border: 1px solid black;
}

.Inventory-programon-lead {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 10px;
}
.Inventory-inventory {
  width: 20vw;
  padding: 10px;
}
