.App {
  height: 100vh;
  width: 90vw;
  margin: auto;
}

.App-content {
  width: 100%;
  height: 90vh;
  position: relative;
}

.App-modal {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
